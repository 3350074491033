
import { Alert, Button, Card, CardActionArea, CardContent, CardMedia, Container, Divider, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { SearchService } from "services/Search";
import ExtendedView from "./extendedView";
import { useNavigate } from "react-router-dom";

const MediaPanel = ({ querySearch, setInProgress }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [cardHeight, setCardHeight] = useState(0);
    const [pages, setPages] = useState([]);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const lastPageRef = useRef(null);
    const lineHeight = 1.38
    const imageRatio = 1.8;
    const imageWidth = 240;
    const imageHeight = imageWidth / imageRatio;

    useEffect(() => {
        const style = window.getComputedStyle(document.querySelector('html'));
        const initialFontSize = Number(style.getPropertyValue('font-size').match(/^(\d*\.?\d*)px$/)[1]);
        setCardHeight(imageHeight + (2 * initialFontSize) + 2 * (initialFontSize * lineHeight));
    }, []);

    useEffect(() => {
        setPages([{
            searchInProgress: true,
            items: []
        }]);
    }, [querySearch]);

    useEffect(() => {
        if (pages.length > 1) {
            lastPageRef.current?.scrollIntoView({
                inline: "start",
                behavior: "smooth",
            });
        }

        if (pages.length && !!querySearch && pages[pages.length - 1].searchInProgress) {
            // console.log("use effect pages", pages, "pages[pages.length -1]:", pages ? pages[pages.length - 1] : null);
            if (setInProgress) {
                setInProgress(true);
            }
            SearchService.getVideos(querySearch, nextPageToken).then((videos) => {
                pages[pages.length - 1].items = videos.items;
                setNextPageToken(videos.nextPageToken);
            }).catch((err) => {
                err.json().then((err) => {
                    console.log("message: ", err?.message);
                    if (!!err?.message?.subscription_expired) {
                        navigate("/error?type=subscriptionExpired&expiredOn=" + new Date(err.message.subscription_expired).toLocaleDateString());
                    }
                });
            }).finally(() => {
                const page = pages.pop();
                page.searchInProgress = false;
                setPages([...pages, page]);
                if (setInProgress) {
                    setInProgress(false);
                }
            });
        }
    }, [pages]);

    const nextPage = () => {
        setPages([...pages, {
            searchInProgress: true,
            items: []
        }]);
    }

    const getSearchInProgressPanel = () => {
        return Array.from({ length: 12 }, (x, i) => (
            <Grid key={"card-skeleton-" + i} xs="auto">
                <Divider sx={{ backgroundColor: "secondary", width: imageWidth, position: "relative", top: imageHeight }}></Divider>
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    sx={{ height: cardHeight, width: imageWidth }}
                />
            </Grid>
        ));
    }

    const getNoObjectsFound = () => (
        <Grid xs sx={{ display: "flex", flexGrow: "1", justifyContent: "center" }} >
            <Alert
                variant="outlined"
                sx={{
                    width: "50%",
                    minWidth: imageWidth,
                    justifyContent: "center",
                    padding: "25px",
                    margin: "25px",
                    alignItems: "center",
                    backgroundColor: "secondary"
                }}
                severity="info"
            >
                <Typography>{t("mediaPanel.noObjectsFound")}</Typography>
            </Alert>
            {/* <Card sx={{ width: "minContent", height: "100%" }}>
                <CardActionArea>
                    <Typography>{t("mediaPanel.noObjectsFound")}</Typography>
                </CardActionArea>
            </Card> */}
        </Grid>
    )

    const getMediaGrid = (items) => (
        items.map((obj, index) => (
            <Grid key={"media-" + index}>
                <Card sx={{ width: "minContent", height: "100%" }} title={obj.title}>
                    <CardActionArea sx={{ width: imageWidth }} onClick={() => {
                        setSelectedMedia(obj);
                    }}>
                        <CardMedia
                            sx={{
                                width: imageWidth,
                                height: imageHeight,
                            }}
                            image={obj.thumbnail.url}
                        />
                        <CardContent>
                            <Typography color="text.secondary"
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                    lineHeight: lineHeight
                                }}>
                                {obj.title}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid >
        ))
    )

    const getPagePanel = (page, ind) => (
        <Grid ref={lastPageRef} container sx={{ justifyContent: "center", alignContent: "flex-start" }} key={"page-" + ind}>
            {page.searchInProgress && getSearchInProgressPanel()}
            {!page.searchInProgress && page.items?.length === 0 && getNoObjectsFound()}
            {!page.searchInProgress && getMediaGrid(page.items)}
        </Grid>
    )

    return (
        <>
            <Container sx={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                <Grid container sx={{ display: "table", justifyContent: "center", alignContent: "flex-start" }} spacing={2}>
                    {
                        pages && pages.map((page, ind) => (
                            getPagePanel(page, ind)
                        ))
                    }
                    {(pages[pages.length - 1]?.items.length !== 0 || pages[pages.length - 1]?.searchInProgress) &&
                        <Grid sx={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                onClick={nextPage}
                                disabled={!nextPageToken || !pages[pages.length - 1].items || pages[pages.length - 1].searchInProgress}>
                                Show More
                            </Button>
                        </Grid>}
                </Grid>
            </Container>
            {selectedMedia &&
                <ExtendedView
                    media={selectedMedia}
                    open={!!selectedMedia}
                    setSelectedMedia={setSelectedMedia}
                />}
        </>
    )
};

export default MediaPanel;