import { Alert, Box, CircularProgress, Container, Snackbar } from "@mui/material";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import React from "react";
import { Config } from "services/Config";
import PaymentService from "services/Payment";

const { loadStripe } = require("@stripe/stripe-js");
const { useState, useEffect } = require("react");

// This is your test public API key.
const stripePromise = loadStripe(Config.getStripeApiKey());

const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState('');

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    console.log("PaymentService.createCheckoutSession")
    PaymentService.createCheckoutSession().then((data) => {
      setClientSecret(data.clientSecret);
    })
  }, []);

  return (
    <Container id="checkout" sx={{ mt: "1em", mb: "1em", display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
      {clientSecret && stripePromise ? (
        <Box id="checkout-box" width={"100%"}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Box>
      ) :
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          flexFlow: 1,
          height: "100%",
          alignItems: "center"
        }}>
          <CircularProgress sx={{ justifySelf: "center" }} />
        </Box>
      }
      <Snackbar key="check_return_snackbar"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ "vertical": "top", "horizontal": "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Oups, something went wrong. Try again!
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default CheckoutForm;