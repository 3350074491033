import { userContext } from "Components/User";
import { useNavigate } from "react-router-dom";

const { Container, Card, CardActions, CardContent, Typography, Button, Box, Stack } = require("@mui/material")
const { default: React, useContext, useEffect } = require("react")


const Pricing = () => {
  // TODO: i18n

  const uContext = useContext(userContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const goGoGo = () => {
    if (!!uContext?.user) {
      navigate("/checkout");
    } else {
      navigate('/signin')
    }
  }

  const question = (question) => (
    <Typography mb={.5} fontWeight={"bold"} textAlign={"start"}>{question}</Typography>
  )

  const answer = (answer) => (
    <Typography pl={"1em"} textAlign={"start"} sx={{ textWrap: "pretty" }} >{answer}</Typography>
  )

  const qaBlock = (q, a) => (
    <Box sx={{ mt: "1.5em" }}>
      {question(q)}
      {answer(a)}
    </Box>
  )
  return (
    <Container
      id="pricing-container"
      sx={{
        textAlign: "center",
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexFlow: "column",
      }}

    >
      <Card variant="outlined" sx={{
        p: '2em',
        mt: '2em'
      }}>
        <CardContent sx={{
          display: "flex",
          flexFlow: "column",

          justifyItems: "center"
        }}>
          <Typography fontWeight={"bold"} fontSize={"x-large"} >
            Common Sense Lab
          </Typography>
          <Typography fontSize={"small"}>
            Monthly Subscription
          </Typography>

          <Container sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 100,
          }}>
            <Typography sx={{
              fontWeight: "bold",
              mr: ".3em",
              fontSize: "xx-large",
            }}>
              $4.99
            </Typography>
            <Typography sx={{
              fontSize: "small",
              lineHeight: "1.1",
              maxWidth: "20px"
            }}>
              per month
            </Typography>
          </Container>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", flexDirection: "column", display: "flex" }}>
          <Button fullWidth variant="contained" onClick={goGoGo}>
            {uContext?.user ? "Subscribe" : "Get started"}
          </Button>
        </CardActions>
      </Card>
      <Typography fontSize={"x-small"} mt={'1em'}>
        *Price and the plan details are subject to change at any time.
      </Typography>

      <Stack sx={{ mt: "3em", mb: "2em", maxWidth: "md" }}>
        <Typography variant="h6" textAlign={"start"}>Pricing FAQs</Typography>
        {qaBlock(
          "Can I cancel my subscription at any time?",
          "Yes, you can cancel your subscription at any time. You will be able to use the service until the end of your billing period."
        )}
        {qaBlock(
          "How do I manage my subscription?",
          "You can manage your subscription from your user settings page."
        )}
        {qaBlock(
          "What happens if I cancel my subscription?",
          "If you cancel your subscription, you will be able to use the service until the end of your billing period. After that, you will lose access to the service."
        )}
        {qaBlock(
          "Who should I contact if I have any questions?",
          'Please send us an email at CommonSenseLabs@gmail.com'
        )}
      </Stack>

    </Container >
  )

}

export default Pricing
