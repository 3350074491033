import { Container, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const Privacy = () => {
    const { t } = useTranslation();

    let [htmlFileString, setHtmlFileString] = useState();

    async function fetchHtml() {
        setHtmlFileString(await (await fetch("locales/en/privacy.html")).text());
    }

    useEffect(() => {
        fetchHtml();
    }, []);

    return (
        <Container sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
        </Container>
    );
};

export default Privacy;