import { Button, Card, CardActions, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogContentText, Divider, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { userContext } from "Components/User";
import React, { useContext, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import UserAvatar from "Components/User/avatar";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentService from "services/Payment";
import SubscriptionService from "services/Subscription";
import LoadingButton from "Components/Atoms/LoadingButton";
import formatCurrency from "utils";
import LaunchIcon from '@mui/icons-material/Launch';

export const Settings = () => {
  // TODO: i18n
  const mTheme = useTheme();
  const uContext = useContext(userContext);
  const navigate = useNavigate();
  const [cancelSubsctiptionModal, setCancelSubsctiptionModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isTrial, setIsTrial] = useState(true);
  const [payments, setPayments] = useState([]);
  const [startAfterPayment, setStartAfterPayment] = useState(null);
  const [loadMore, setLoadMore] = useState(true);
  const [loadPayments, setLoadPayments] = useState(false);
  const [createSessionInProgress, setCreateSessionInProgress] = useState(false);
  const [cancelSubscriptionInProgress, setCancelSubscriptionInProgress] = useState(false);

  useEffect(() => {
    console.log("Settings: uContext: ", uContext);
    setLoading(!uContext.subscription);
    setIsTrial(uContext.isTrial);
  }, [uContext.subscription]);

  useEffect(() => {
    if (!loadMore) {
      return;
    }
    setLoadMore(false);
    setLoadPayments(true);
    PaymentService.getPayments(startAfterPayment).then((data) => {
      setPayments([...payments, ...data.payments]);
      setStartAfterPayment(data.starting_after);
    }).finally(() => {
      setLoadPayments(false);
    });
  }, [loadMore]);

  const createSubscriptionSession = () => {
    navigate("/checkout")
  };

  const handleClose = () => {
    setCancelSubsctiptionModal(false);
  };

  const cancelSubscription = () => {
    setCancelSubscriptionInProgress(true);
    SubscriptionService.cancelSubscription().then(() => {
      navigate("/subscription/cancelled");
    }).finally(() => {
      setCancelSubscriptionInProgress(false);
      handleClose();
    });
  }

  const profileDetailsPanel = () => (
    <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <UserAvatar size={52} />
      <Typography variant="h6">Welcome, {uContext.name}</Typography>
      <Typography textAlign={"center"} sx={{ textWrap: "pretty" }}>Manage your settings here to make Common Sense Lab work better for you</Typography>
    </Container>
  );

  const trialPanel = () => {
    const isExpired = new Date(uContext.subscription.expires_at) < new Date();
    const expirationDate = new Date(uContext.subscription.expires_at).toLocaleDateString();
    return (
      <>
        <Typography gutterBottom>
          {isExpired ?
            "Your free 3-days trial period has expired as of " + expirationDate + "."
            :
            "You are currently on a free 3-days trial period that ends on " + expirationDate + "."}
        </Typography>
        <Typography>
          Upgrade to our monthly subscription to unlock full benefits now!
        </Typography>
      </>
    );
  };

  const subscriptionPanel = () => {
    return (
      <Card variant="outlined">
        <CardHeader
          title={<>
            <Typography variant="h6" component="div">Subscription</Typography>
            <Divider />
          </>}
          avatar={<PersonIcon />} />
        <CardContent>
          {loading ?
            <Skeleton sx={{ height: "3em", }} /> :
            isTrial ? trialPanel() : subscriptionInfoPanel()}
        </CardContent>
        <CardActions sx={{ justifyContent: loading || isTrial ? "center" : "end" }}>
          {loading ?
            <Skeleton variant="rectangular" sx={{ height: "2em", width: "15em" }} /> :
            isTrial || uContext.subscription?.status === "cancelled" ?
              <LoadingButton
                inProgress={createSessionInProgress}
                onClick={createSubscriptionSession}
                buttonSx={{ maxWidth: "400px" }}
                boxSx={{ width: "100%" }}
                buttonFullWidth={true}
                text="Subscribe"
              />
              :
              <Button
                size="small"
                onClick={() => setCancelSubsctiptionModal(true)}
              >
                Cancel Subscription
              </Button>}
        </CardActions>
      </Card >
    );
  };

  const activeSubscription = () => (
    <>
      <Typography gutterBottom>Your monthly subscription has been active from {new Date(uContext.subscription.created_at).toLocaleDateString()}.</Typography>
      <Typography>We appreciate your continued support!</Typography>
    </>
  )

  const cancelledSubscription = () => {
    const isExpired = new Date(uContext.subscription.expires_at) < new Date();
    const text = isExpired ?
      "Your monthly subscription has been cancelled." :
      `Your monthly subscription has been cancelled but the service is still active until ${new Date(uContext.subscription.expires_at).toLocaleDateString()}.`;
    return (
      <>
        <Typography gutterBottom>{text}</Typography>
        <Typography>Subscribe again!</Typography>
      </>
    );
  }

  const subscriptionInfoPanel = () => {
    const isCancelled = uContext.subscription.status === "cancelled";
    return isCancelled ? cancelledSubscription() : activeSubscription();
  };

  const paymentsInfoPanel = () => {
    if (payments.length === 0) {
      return (<Typography>No payments have been made yet.</Typography>);
    }
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">View Receipt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment) => (
              <TableRow
                key={payment.created}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell >
                  {new Date(payment.created * 1000).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </TableCell>
                <TableCell align="right">{formatCurrency(payment.currency, payment.amount)}</TableCell>
                <TableCell align="right" sx={{ alignItems: "center" }}>
                  {/* <Button

                                        href={payment.receipt_url}
                                        target="_blank"
                                        endIcon={<LaunchIcon sx={{ color: mTheme.palette.text.primary }} />}
                                    /> */}
                  <IconButton
                    href={payment.receipt_url}
                    target="_blank"
                  >
                    <LaunchIcon
                      fontSize="small"
                      sx={{ color: mTheme.palette.text.primary }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)
  };

  const paymentsPanel = () => {
    return (
      <Card variant="outlined">
        <CardHeader
          title={<>
            <Typography variant="h6" component="div">Payments</Typography>
            <Divider />
          </>}
          avatar={<CreditCardIcon />} />
        <CardContent>
          {loading || loadPayments ?
            <Skeleton sx={{ height: "3em", }} /> :
            isTrial ?
              <>
                <Typography gutterBottom>Enjoy your exploration worry-free!</Typography>
                <Typography gutterBottom>Your trial period is completely on us, so no payments have been made.</Typography>
              </>
              :
              paymentsInfoPanel()}
        </CardContent>
        {!isTrial && !loading && !!payments.length && startAfterPayment &&
          <CardActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={() => setLoadMore(true)}
            >
              Load More
            </Button>
          </CardActions>}
      </Card>
    );
  };

  return (
    <>
      <Container sx={{ flexGrow: "1", display: "flex", justifyContent: "center", mt: "1em" }}>
        <Grid id="settings" xs={12} sm={10} md={8} spacing={3} sx={{ mt: "1em", mb: "1em", minWidth: "250px" }} container direction={"column"}>
          <Grid display={"flex"} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
            {profileDetailsPanel()}
          </Grid>
          <Grid>
            {subscriptionPanel()}
          </Grid>
          <Grid>
            {paymentsPanel()}
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={cancelSubsctiptionModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel your subscription?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>No</Button>
          <LoadingButton
            inProgress={cancelSubscriptionInProgress}
            onClick={cancelSubscription}
            text="Cancel Subscription"
            buttonVariant={"text"}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Settings;