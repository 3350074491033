import { AppBar, Box, Container, Divider, Link, Popover, Toolbar, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorContactEl, setAnchorContactEl] = useState(null);
    const navigate = useNavigate();
    const theme = useTheme();

    const handleAboutClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleContactUs = (event) => {
        setAnchorContactEl(event.currentTarget);
    };

    const showTerms = () => {
        navigate("/terms")
    };

    const showPricing = () => {
        navigate("/pricing")
    };

    const showPrivacy = () => {
        navigate("/privacy")
    };

    const handleCloseAbout = () => {
        setAnchorEl(null);
    };

    const handleCloseContactUs = () => {
        setAnchorContactEl(null);
    };

    return (
        <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{
                // borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                backgroundColor: (theme) => theme.palette.background.default,
                top: 'auto',
                bottom: 0,
            }}
        >
            <Toolbar id="footer-toolbar" sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center", flexFlow: { xs: "column-reverse", sm: "row" } }}>
                    <Typography sx={{ flexGrow: 1, fontSize: "smaller", display: { sm: 'flex' } }}>
                        {t("footer.allRights", { "year": new Date().getFullYear() })}
                    </Typography>
                    <Box display={"flex"} flexGrow={1} justifyContent="end" sx={{ display: "flex" }}>

                        <Link component={"a"} onClick={showPricing} color="" underline="hover" sx={{ cursor: "pointer", fontSize: "smaller", ml: "1em" }}>
                            {t("footer.pricing")}
                        </Link>

                        <Link component={"a"} onClick={showTerms} color="" underline="hover" sx={{ cursor: "pointer", fontSize: "smaller", ml: "1em" }}>
                            {t("footer.terms")}
                        </Link>

                        <Link component={"a"} onClick={showPrivacy} color="" underline="hover" sx={{ cursor: "pointer", fontSize: "smaller", ml: "1em" }}>
                            {t("footer.privacy")}
                        </Link>

                        <Link component={"a"} onClick={handleContactUs} color="" underline="none" sx={{ cursor: "pointer", fontSize: "smaller", ml: "1em" }}>
                            {t("footer.contactUs")}
                        </Link>

                        <Link component={"a"} onClick={handleAboutClick} color="" underline="none" sx={{ cursor: "pointer", fontSize: "smaller", ml: "1em" }}>
                            {t("footer.about")}
                        </Link>
                    </Box>
                </Box>
            </Toolbar >

            <Popover
                sx={{ marginTop: "-5px" }}
                id={"popover"}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleCloseAbout}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Typography sx={{ p: 2 }}>{t("footer.aboutText")}</Typography>
            </Popover>

            <Popover
                sx={{ marginTop: "-5px" }}
                id={"popover"}
                open={!!anchorContactEl}
                anchorEl={anchorContactEl}
                onClose={handleCloseContactUs}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Container sx={{ p: 3 }} >
                    <Typography fontWeight={"bold"}>{t("footer.contact.title")}</Typography>
                    <br /><Typography maxWidth={"550px"}>{t("footer.contact.note")}</Typography>
                    <br />{t("footer.contact.at")} <Link href={`mailto: ${t("global.email")}?subject=Feedback`}>{t("footer.contact.email")}</Link>
                    <br />
                    <br /> {t("footer.contact.innovate")}
                </Container>
            </Popover>
        </AppBar>
    )
};

export default Footer;