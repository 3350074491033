
import { Button, Card, CardContent, CardHeader, Container, Icon, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { userContext } from "Components/User";
import YoutubeEmbed from "Components/YouTube";


const Hero = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const uContext = useContext(userContext);

  const block = (header, text, newLine = true, card = true) => {
    return (
      <Grid style={{
        alignItems: "center",
        textAlign: !newLine ? "center" : "start",
      }}>
        {card ? <Card variant="outlined" sx={{ maxWidth: "650px" }}>
          <CardHeader title={header} sx={{ borderLeft: "3px solid darkorange" }} />
          <CardContent>
            {text}
          </CardContent>
        </Card> :
          <>
            <Typography
              sx={{
                // fontWeight: "bold",
                marginBottom: "5px"
              }}
              variant="h6"
            >
              {header}
            </Typography>
            <Typography
              sx={{
                borderLeft: newLine ? "solid darkorange 3px" : "",
                paddingLeft: newLine ? "1em" : "",
                // fontWeight: "bold",
                textWrap: "pretty"
              }}
            >
              {text}
            </Typography>
          </>
        }
      </Grid>
    );
  }

  return (
    <Container
      id="hero-container"
      sx={{
        textAlign: "center",
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        p: 0
      }}
    >
      <Grid container
        spacing={3}
        justifyContent={"center"}
        alignContent={"space-around"}
        // xs={12} sm={11} md={10} lg={9} xl={9}
        sx={{ margin: "0.5em", maxWidth: "md" }}

      >
        <Grid>
          <Typography
            variant="h4"
            sx={{
              paddingTop: "1em",
              paddingBottom: "1em",
              // margin: "3em 0 3em 0",
              fontWeight: "900",
              textAlign: "center",
              textWrap: "balance"
            }}
          >
            {t("hero.header")}
          </Typography>
        </Grid>

        <Grid alignItems={'center'} xs={12} sm={8}>
          <Button
            href="https://chromewebstore.google.com/detail/comment-insights-interact/paldpbnknkppalhapfjegedgagoilbfi"
            target="_blank"
            variant="contained"
            style={{ fontSize: "1.1em", fontWeight: "bold", color: "white" }}
            startIcon={
              // <ChromeStoreIcon />
              <Icon >
                <img style={{ height: "100%", display: "flex", width: "20px" }} src="chrome-icon48.png" />
              </Icon>
            }
          >
            {t("hero.installOnChrome")}
          </Button>
        </Grid>
        <Grid xs={12} sm={8}>
          <YoutubeEmbed embedId="teXIQpFrbUc" width="100%" thumbnail={"youTubePromo.webp"} />
        </Grid>
        {/* <Grid >
          <Card variant="elevation" sx={{maxWidth: "350px"}}>
            <CardHeader title={t("hero.welcomeHeader")}/>
            <CardContent>
              {t("hero.welcomeText1")}
            </CardContent>
          </Card>
        </Grid> */}
        <Grid xs={12} sm={10} textAlign={"center"} sx={{ mt: "2em" }}>
          <Typography variant={"h5"} sx={{ fontWeight: "bold", textWrap: "pretty" }}>
            {/* Discover Comment Insights: Your Tool for Audience Clarity
            Meet Comment Insights */}
            Comment Insights – Your Interactive Comment Analyzer
          </Typography>
        </Grid>
        {
          block(t("hero.welcomeHeader"), t("hero.welcomeText1"))
        }
        {
          block(t("hero.whyHeader"), t("hero.whyText"))
        }
        {
          block(t("hero.solutionHeader"), t("hero.solutionText"))
        }
        {
          block(t("hero.finalHeader"), t("hero.finalText"), false, false)
        }
        <Grid xs={12}>
          <Button
            variant="contained"
            onClick={() => uContext?.user ? navigate("search") : navigate("signin")}
            sx={{ fontWeight: "bold", color: "white", width: "316px" }}
          >
            {t("hero.getStarted")}
          </Button>
        </Grid>
      </Grid>
    </Container >
  )
};

export default Hero;