import React, { useContext, useEffect, useState } from 'react';

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Box, Container } from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { ThemeProvider } from '@aws-amplify/ui-react';
import { getTheme } from './theme';
import { Amplify } from 'aws-amplify';
import { getAuthConfig } from './amplifyConfig';
import { useNavigate } from 'react-router-dom';
import { userContext } from 'Components/User';

Amplify.configure(getAuthConfig());

const SignIn = () => {
  const mTheme = useTheme();
  const [customTheme, setCustomTheme] = useState(null);
  const navigate = useNavigate();
  const uContext = useContext(userContext);
  // const [inProgress, setInProgress] = useState(null);
  const { route } = useAuthenticator(context => [context.route]);

  useEffect(() => {
    if (!customTheme) {
      setCustomTheme(getTheme(mTheme));
    }
  }, []);

  useEffect(() => {
    if (uContext?.user && route === "authenticated") {
      navigate("/search");
    }
    // setInProgress(inProgress !== "signIn" && inProgress === "signUp");
  }, [uContext, route]);

  return (
    <Container
      fixed
      sx={{
        display: "flex",
        justifyContent: "center",
        flexGrow: 1,
        alignContent: "center",
        p: 0
      }}>
      <ThemeProvider theme={customTheme} colorMode={mTheme?.palette.mode}>
        <Box sx={{ display: "flex", height: "100%" }}>
          {/* {inProgress && <LinearProgress sx={{ width: "400px" }} />} */}
          <Authenticator
            className='signInButton'
            loginMechanisms={['email']}
            socialProviders={['google']}
          >
            {/* should be redirecting to the search page via userContext */}
          </Authenticator>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default SignIn;