import { Container, Typography } from "@mui/material";
import React, { useContext } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { userContext } from "Components/User";

export const SubscriptionCancelled = () => {
    // TODO: i18t
    const uContext = useContext(userContext);

    return (
        <Container sx={{ display: "flex", flexGrow: "1", justifyContent: "center" }}>
            <Grid container flexGrow={1} justifyContent={"center"} mt={1} mb={1} spacing={2}>
                <Grid xs={12} sm={8} >
                    <Typography variant="h6" textAlign={"center"} mt={2}>
                        Your Subscription Has Been Successfully Cancelled
                    </Typography >
                    <Typography mt={2}>
                        We've received and processed your request to cancel your subscription.
                    </Typography>
                    <Typography mt={3}>
                        While it's sad to see you go, we respect your decision and are here to support you every step of the way.
                    </Typography>

                    <Typography fontWeight={"bold"} mt={3}>
                        Here’s What Happens Next:
                    </Typography>
                    <ul>
                        <li>
                            <Typography sx={{ fontWeight: "bold" }}>Access Until {new Date(uContext.subscription.expires_at).toLocaleDateString()}:</Typography>
                            <Typography>Your service will remain active until the end of your current billing period on {new Date(uContext.subscription.expires_at).toLocaleDateString()}. Until then, you'll continue to have full access to all the features and benefits you've enjoyed so far.</Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontWeight: "bold" }}>No More Billing</Typography>
                            <Typography>Rest assured, you won't be billed any further once your subscription period ends.  Your subscription will not auto-renew.</Typography>
                        </li>
                        <li>
                            <Typography sx={{ fontWeight: "bold" }}>Come Back Anytime</Typography>
                            <Typography>If you ever decide to come back or explore our services again, remember that reactivating your subscription is easy. We would love to welcome you back at any time.</Typography>
                        </li>
                    </ul>

                    <Typography sx={{ fontWeight: "bold" }} mt={3}>Your Feedback Matters</Typography>
                    <Typography>If you’re open to sharing why you decided to cancel, please send us a message to <a style={{ color: "dodgerblue" }} href="mailto:commonsenselabs@gmail.com">commonsenselabs@gmail.com</a>, we’d appreciate hearing from you. Your feedback is invaluable and helps us improve. </Typography>

                    <Typography sx={{ fontWeight: "bold" }} mt={3}>Need Help or Have Questions?</Typography>
                    <Typography>Should you have any questions or need assistance during your remaining time with us, don't hesitate to reach out. Our support team is here for you and can be contacted at <a style={{ color: "dodgerblue" }} href="mailto:commonsenselabs@gmail.com">commonsenselabs@gmail.com</a>.</Typography>

                    <Typography mt={3}>
                        Thank you for being a part of the Common Sense Lab community. It’s been our pleasure to serve you, and we hope our paths cross again in the future.
                    </Typography>
                    <Typography mt={3}>
                        Warm regards,
                    </Typography>
                    <Typography >
                        The Common Sense Lab Team
                    </Typography>
                    {/* 
                    <Typography sx={{ whiteSpace: "pre-line" }}>
                        {`
        
                        We've received and processed your request to cancel your subscription. While it's sad to see you go, we respect your decision and are here to support you every step of the way.
        
                        What Happens Next?
        
                        Uninterrupted Access: Your journey with [Service/Product Name] doesn't end here. You'll continue to have full access to all the features and benefits until the end of your current billing cycle on [End Date]. We hope you make the most of your remaining time with us.
                        No More Billing: This is a one-time confirmation that there will be no further charges. Your subscription will not auto-renew, and your account will move to a free plan after the above date, should our service offer one.
                        A Door Always Open: If you ever decide to come back or explore our services again, remember that reactivating your subscription is easy. We would love to welcome you back at any time.
                        Need Help or Have Questions?
                        Should you have any questions or need assistance during your remaining time with us, don't hesitate to reach out. Our support team is here for you and can be contacted at [Support Contact Information].
        
                        Your Feedback Matters
                        If you’re open to sharing why you decided to cancel, we’d appreciate hearing from you. Your feedback is invaluable and helps us improve. [Link to Feedback Form].
        
                        Thank you for being a part of the [Service/Product Name] community. It’s been our pleasure to serve you, and we hope our paths cross again in the future.
        
                        With appreciation,
        
                        The [Your Company Name] Team
                        `}
                    </Typography> */}
                </Grid>
            </Grid>
        </Container>
    );
}
