import { Alert, Box, Card, CardContent, Container, LinearProgress, Link, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import PaymentService from "services/Payment";

const ErrorPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const urlSearchParams = new URLSearchParams(useLocation().search);
    const [params, setParams] = useState(null);

    useEffect(() => {
        const params = Object.fromEntries(urlSearchParams.entries());
        console.log("Error: ", params)
        setParams(params);
    }, []);

    const error_panel = (title, message) => (
        <Card variant="outlined">
            <Alert severity="error">{title}</Alert>
            <CardContent sx={{ textWrap: "balance", textAlign: "center" }}>
                {message}
            </CardContent>
        </Card>
    )

    const checkout = () => {
        navigate("/checkout");
    }

    const get_error_message = () => {
        if (params?.type === "subscriptionExpired") {
            let date = new Date(params.expiredOn);
            // console.log("date: ", date);
            if (isNaN(date)) {
                // invalid expiredOn value
                date = new Date()
            }

            return (
                <Box sx={{ textAlign: "center" }}>
                    {error_panel(
                        t("errorPage.subscriptionExpiredAlert"),
                        t("errorPage.subscriptionExpired", { "expiredOn": date.toLocaleDateString(), interpolation: { escapeValue: false } })
                    )}
                    <Typography sx={{ mt: 4 }}>
                      {t("errorPage.toRenew1")}
                      <Link onClick={checkout}>{t("errorPage.here")}</Link>
                      {t("errorPage.toRenew2")} 
                    </Typography>
                </Box>

            )
        } else if (params?.type === "ai_requests_limit") {
            return error_panel(
                t("errorPage.ai_requests_limit_reached_title"),
                t("errorPage.ai_requests_limit_reached")
            )
        } else {
            <p>{t("errorPage.title")}</p>
        }
    }

    return (
        <Container sx={{ display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
            <Grid xs={12} sm={9} md={7} lg={6} xl={5}>
                {get_error_message()}
            </Grid>
        </Container>
    )
};

export default ErrorPage;