import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Hero from 'Components/Hero';
import Search from 'Components/Search';
import ErrorPage from 'Components/Error';
import NoPage from 'Components/NoPage';
import SignIn from 'Components/SignIn';
import Header from 'Components/Layout/Header';
import Footer from 'Components/Layout/Footer';
import { Container, CssBaseline, Stack, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { userContext } from 'Components/User';
import { fetchUserAttributes } from 'aws-amplify/auth';
import Terms from 'Components/Layout/Terms';
import Pricing from 'Components/Layout/Pricing';
import SubscriptionService from 'services/Subscription';
import Privacy from 'Components/Layout/Privacy';
import CheckoutForm from 'Components/Payment';
import CheckoutReturn from 'Components/Payment/CheckoutReturn';
import Settings from 'Components/Settings';
import { SubscriptionCancelled } from 'Components/Settings/SubscriptionCancelled';


function App() {
  const fonts = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ];
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    // && false
    ;

  const [userState, setUserState] = useState({});

  const { user, signOut, authStatus } = useAuthenticator((context) => [context.user, context.signOut]);

  useEffect(() => {
    if (!!user && authStatus === "authenticated") {

      const fetchUserPromise = fetchUserAttributes().catch((error) => error);
      const subscriptionPromise = SubscriptionService.getSubsription().catch((error) => error);

      Promise.all(
        [fetchUserPromise, subscriptionPromise]
      ).then(([userAttributes, subscription]) => {
        const newUser = {
          user: user,
          signOut: signOut,
          authStatus: authStatus
        };
        if (userAttributes instanceof Error) {
          console.error("user attributes error: ", userAttributes);
        } else {
          newUser.name = userAttributes.name ?? userAttributes.email;
          newUser.picture = userAttributes.picture;
        }

        if (subscription instanceof Error) {
          console.error("subscription error: ", subscription);
        } else {
          newUser.subscription = subscription;
          newUser.isTrial = !subscription || subscription.status === "trial";
        }
        setUserState(newUser);
      }).catch(err => {
        console.error("Oups...", err)
      });
    } else {
      setUserState({
        authStatus: authStatus
      });
    }
  }, [user, authStatus]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          background: {
            default: prefersDarkMode ? "#000" : "#F1F2F3",
          },
          primary: {
            main: "#ff8c00",
          },
        },
        typography: {
          fontFamily: fonts.join(','),
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <userContext.Provider value={userState}>
        <Container id="main-box" maxWidth="lg" sx={{ display: "flex", height: "100%", p: 0 }}>
          <Stack id="main-stack" style={{
            flexGrow: "1",
          }}>
            <BrowserRouter>
              <Header user={user} />
              <Routes>
                <Route>
                  <Route index element={<Hero />} />
                  <Route path="*" element={<NoPage />} />
                  <Route path="checkout" element={<CheckoutForm />} />
                  <Route path="checkoutReturn" element={<CheckoutReturn />} />
                  <Route path="error" element={<ErrorPage />} />
                  <Route path="privacy" element={<Privacy />} />
                  <Route path="search" element={<Search />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="signin" element={<SignIn />} />
                  <Route path="subscription/cancelled" element={<SubscriptionCancelled />} />
                  <Route path="terms" element={<Terms />} />
                  <Route path="pricing" element={<Pricing />} />
                </Route>
              </Routes>
              <Footer />
            </BrowserRouter>
          </Stack>
        </Container>
      </userContext.Provider>
    </ThemeProvider>
  );
}

export default App;
