
import i18n from "i18next";

const formatCurrency = (currency, amount) => {
    return Intl.NumberFormat(
        i18n.language,
        {
            style: "currency",
            currency: currency
        }
    ).format(amount / 100);
}

export default formatCurrency;