import { Badge, Box, Button, Card, CardActions, CardContent, Container, LinearProgress, Table, TableBody, TableCell, TableRow, Typography, tableCellClasses, useTheme } from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentService from "services/Payment";
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from "react-i18next";

const { useState, useEffect } = require("react");


const CheckoutReturn = () => {
    const [receipt, setReceipt] = useState(null);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const { i18n } = useTranslation();

    const formatCurrency = (currency, amount) => {
        return Intl.NumberFormat(
            i18n.language,
            {
                style: "currency",
                currency: currency
            }
        ).format(amount / 100);
    }

    useEffect(() => {
        const sessionId = searchParams.get("session_id");
        if (!sessionId) {
            console.error("CheckoutReturn: no session_id");
            return;
        }
        PaymentService.getReceipt(sessionId).then((receipt) => {
            console.log("PaymentService receipt: ", receipt);
            if (receipt.session_status !== "complete") {
                // oups...
                console.error("CheckoutReturn: payment didn't get through: ", receipt);
                navigate("/checkout?retry=true");
            } else {
                setReceipt(receipt);
            }
        });
    }, []);

    const payment_received_panel = () => (
        <Card>
            <CardContent >
                <Box display={"flex"} justifyContent={"center"} mt={".5em"}>
                    <Badge
                        badgeContent={
                            <CheckCircleIcon
                                fontSize="small"
                                color="success"
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    borderRadius: "50%"
                                }}

                            />
                        }
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        overlap="circular"
                    >
                        <ReceiptOutlinedIcon fontSize="large" />
                        {/* <ReceiptIcon fontSize="large" /> */}
                    </Badge>
                </Box>

                <Typography variant="h5" m={"1em"}>
                    Thank you for your order!
                </Typography>

                <Table aria-label="simple table"
                    sx={{
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none"
                        },
                    }}
                    size="small"
                >
                    <TableBody>
                        <TableRow >
                            <TableCell >Name:</TableCell>
                            <TableCell >{receipt.customer_name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >Email:</TableCell>
                            <TableCell >{receipt.receipt_email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >Order Total:</TableCell>
                            <TableCell >{formatCurrency(receipt.currency, receipt.amount_total)}</TableCell>
                        </TableRow>
                        {receipt.receipt_number ? (
                            <TableRow>
                                <TableCell >Receipt number:</TableCell>
                                <TableCell >{receipt.receipt_number}</TableCell>
                            </TableRow>
                        ) : null}
                        <TableRow>
                            <TableCell >Paid:</TableCell>
                            <TableCell >{new Date(receipt.created * 1000).toLocaleString()}</TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </CardContent>
            <CardActions sx={{ justifyContent: "space-between", mr: ".5em", ml: ".5em" }}>
                <Button
                    href={receipt.receipt_url}
                    target="_blank"
                    endIcon={<LaunchIcon />}
                >
                    View Receipt
                </Button>
                <Button
                    href="/search"
                >Close</Button>
            </CardActions>
        </Card >
    )
    return (
        <Container key="check_return_container" id="checkoutReturn"
            sx={{
                mt: "1em",
                mb: "1em",
                flexGrow: 1,
                justifyContent: "center"
            }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                flexFlow: 1,
                height: "100%",
                alignItems: "center"
            }}>
                {!!receipt
                    ? payment_received_panel()
                    : <Box minWidth={"250px"} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                        <Typography mb="1em" textAlign={"center"}>Payment Processing...</Typography>
                        <LinearProgress />
                    </Box>
                }
            </Box>
        </Container>
    )
}

export default CheckoutReturn;

